import React from "react"

const Error = () => {
  return (
    <main className="error-page">
      <section>
        <h1>404</h1>
        <p>Page Not Found</p>
      </section>
    </main>
  )
}

export default Error
